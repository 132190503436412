import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { css } from 'theme-ui'

const ImageComponent = ({ image, sx, ...props }) => {
  if (!image?.src) return null

  // SVG Asset
  const isSVG = image.src.extension === 'svg'
  if (isSVG) {
    return (
      <img
        alt={image.alt}
        src={image.src.publicURL}
        style={{
          width: image.width || `100%`,
          maxWidth: image.maxWidth || `none`
        }}
        {...props}
      />
    )
  }

  // Image Asset
  const imageData = getImage(image.src)
  if (imageData) {
    return (
      <GatsbyImage
        image={imageData}
        alt={image.alt}
        css={css({
          verticalAlign: `middle`,
          borderStyle: image.border ? `solid` : `none`,
          borderWidth: image.border || 0,
          borderColor: `white`,
          boxShadow: image.shadow || `unset`,
          borderRadius: image.radius || `unset`,
          ...sx
        })}
        {...props}
      />
    )
  }

  return null
}

export default ImageComponent
