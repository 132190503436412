import React from 'react'
import Reveal from '@solid-ui-components/Reveal'
import { css } from 'theme-ui'
import ImageComponent from './ImageComponent'
import { useWindowSize } from '../../../helpers'

const AdditionalImages = ({
    images,
    loading,
}) => {
  const windowSize = useWindowSize();

  return images ? (
    <>
      {windowSize > 640 && <>
        {images?.slice(1)?.map(
          (image, index) =>
            image.src && (
              <Reveal
                key={`item-${index}`}
                effect={image.effects[0] || undefined}
                delay={0.5}
                css={css({
                  ...image.position,
                  position: `absolute`,
                  display: [`none`, `block`]
                })}
              >
                <Reveal
                  effect={image.effects[1] || undefined}
                  style={{ backfaceVisibility: `hidden` }}
                >
                  <ImageComponent image={image} loading={loading} />
                </Reveal>
              </Reveal>
            )
        )}
      </>}
    </>
  ) : null;
}


export default AdditionalImages