import React from 'react'
import FooterBlock from '@solid-ui-blocks/Footer/Block01'
import { StaticQuery, graphql } from 'gatsby';
import { normalizeBlockContentNodes } from '@blocks-helpers'

export const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query footerBlockContent {
          allBlockContent(
            filter: { page: { in: ["site-shared"] } }
          ) {
            nodes {
              ...BlockContent
            }
          }
        }
      `}
      render={data => {
        const { allBlockContent } = data
        const content = normalizeBlockContentNodes(allBlockContent?.nodes)

        return (
          <FooterBlock content={content['footer']} />
        )
      }}
    />
  )
}

