import React from 'react'
import { css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ImageComponent from './ImageComponent'
import AdditionalImages from './AdditionalImages'

const ContentImages = ({
  content: { images },
  reverse,
  imagePosition,
  objectPosition,
  imageFit,
  imageEffect,
  loading,
  sx
}) => {
  return images ? (
    <>
      <Reveal
        effect={(images && images[0] && images[0].effects && images[0].effects[0]) || undefined}
        css={css({
          textAlign:
            imagePosition === 'center'
              ? 'center'
              : reverse
              ? `right`
              : undefined
        })}
      >
        {images && images[0] && images[0].href
          ? <a
            href={images[0].href}
            target={images[0].target ? images[0].target : '_self'}
          >
            <ImageComponent
              image={images[0]}
              loading={loading}
              objectFit={imageFit}
              objectPosition={objectPosition}
              sx={sx}
            />
          </a>
          : <ImageComponent
            image={images[0]}
            loading={loading}
            objectFit={imageFit}
            objectPosition={objectPosition}
            sx={sx}
          />}
      </Reveal>
      <AdditionalImages
        images={images}
        loading={loading}
      />
    </>
  ) : null
}

ContentImages.defaultProps = {
  loading: 'lazy'
}

export default ContentImages
