import colors from '@elegantstack/solid-ui-theme/src/color-preset'

export default {
  // Alpha (primary)
  alphaLighter: colors.blueGray[100],
  alphaLight: colors.blueGray[300],
  alpha: colors.blueGray[500],
  alphaDark: colors.blueGray[600],
  alphaDarker: colors.blueGray[800],
  // beta (secondary)
  betaLighter: colors.blue[100],
  betaLight: colors.blue[300],
  beta: colors.blue[500],
  betaDark: colors.blue[700],
  betaDarker: colors.blue[800]
}
